@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');

@mixin text_body($color: $text-body-default-font-color)
{
    font-family: lato;

    color: $color;
}

@mixin text_code($color: $text-code-default-font-color)
{
    font-family: lato;
    font-weight: 600;

    color: $color;
}

@mixin text_headline($color: $text-headline-default-font-color)
{
    font-family: lato;

    color: $color;
}
